<template>
  <main class="flex-1 relative overflow-y-auto focus:outline-none" tabindex="0">
      <div class="py-6">
        <div class="px-4 sm:px-6 md:px-8">
          <router-view />
        </div>
      </div>
    </main>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {

  }
}
</script>
