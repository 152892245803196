import Vue from 'vue'
import Vuex from 'vuex'

import securityModule from "./security";
import themeModule from "./theme";

Vue.use(Vuex)

const createStore = () => {
    return new Vuex.Store({
        modules: {
            securityModule: securityModule,
            themeModule: themeModule
        }
    });
};

export default createStore;

// export default new Vuex.Store({
//     state: {
//         isAuthenticated: false,
//         user: {},
//         theme: {},
//     },
//     getters: {
//         currentUser(state) {
//             return state.user;
//         },
//         isAuthenticated(state) {
//             return state.isAuthenticated;
//         },
//         getTheme: (state) => {
//             return state.theme;
//         }
//     },
//     mutations: {
//         // put sychronous functions for changing state e.g. add, edit, delete
//         setAuth(state, user) {
//             state.isAuthenticated = true;
//             state.user = user;
//         },
//         purgeAuth(state) {
//             state.isAuthenticated = false;
//             state.user = {};
//         },
//         SET_THEME(state, theme) {
//             state.theme = theme;
//             localStorage.theme = theme;
//         }

//     },
//     actions: {
//         // put asynchronous functions that can call one or more mutation functions
//         async LOGIN(context, email, password) {
//             try {
//                 const user = await Auth.signIn(email, password).then((user) => {
//                     context.commit('setAuth', user);
//                 });
//                 alert(`${user} has successfully logged in`);
//                 console.log(user)
//             } catch (error) {
//                 alert(error.message);
//             }
//         },
//         initTheme({ commit }) {

//             const cachedTheme = localStorage.theme ? localStorage.theme : false;
//             //  `true` if the user has set theme to `dark` on browser/OS
//             const userPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

//             if (cachedTheme)
//                 commit('SET_THEME', cachedTheme)
//             else if (userPrefersDark)
//                 commit('SET_THEME', 'dark')
//             else
//                 commit('SET_THEME', 'light')

//         },
//         toggleTheme({ commit }) {
//             switch (localStorage.theme) {
//                 case 'light':
//                     commit('SET_THEME', 'dark')
//                     break;
    
//                 default:
//                     commit('SET_THEME', 'light')
//                     break;
//             }
//         },
        
//     }
// })