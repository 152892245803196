const setAuth = (state, user) => {
    state.isAuthenticated = true;
    state.user = user;
};

const purgeAuth = (state) => {
    state.isAuthenticated = false;
    state.user = {};
};

export default { 
    setAuth,
    purgeAuth
}