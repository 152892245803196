const currentUser = (state) => {
    return state.user;
};

const isAuthenticated = (state) => {
    return state.isAuthenticated;
};

export default {
    currentUser,
    isAuthenticated
}