import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import './assets/styles/tailwind.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueProgressBar from 'vue-progressbar'
import Vuex from 'vuex'


Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(Vuex);
Vue.component('toast-message', require('./components/ToastNotification.vue').default);

Vue.use(VueProgressBar, {
  color: 'rgb(199, 48, 194)',
  failedColor: 'red',
  height: '2px'
})

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
