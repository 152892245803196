<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
<div v-if="l_visible" class="fixed inset-10 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
 
  <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
    <div class="p-4">
      <div class="flex items-start">


        <div v-if="l_msgType === '1'" class="flex-shrink-0">
          <!-- Heroicon name: outline/check-circle -->
          <svg class="h-6 w-6 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>


        <div v-if="l_msgType === '2'" class="flex-shrink-0">
          <!-- Heroicon name: outline/check-circle -->
          <svg class="h-6 w-6 text-red-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>

        <div v-if="l_msgType === '3'" class="flex-shrink-0">
          <!-- Heroicon name: outline/check-circle -->
          <svg class="h-6 w-6 text-yellow-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>

        <div class="ml-3 w-0 flex-1 pt-0.5">
          <p class="text-sm font-medium text-gray-900">
            {{l_titleMessage}}
          </p>
          <p class="mt-1 text-sm text-gray-500">
            {{l_content}}
          </p>
        </div>
        <div class="ml-4 flex-shrink-0 flex">
          <button @click="closeNotification" class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">Close</span>
            <!-- Heroicon name: solid/x -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { Bus } from '../evtbus/event-bus';

export default {
    name: "ToastNotification",
    data () {
        return {
            l_titleMessage: null,
            l_content: null,
            l_msgType: null,
            l_visible: false 
        }
    },
    mounted() {
        let timer;
        Bus.$on('toast-message', (message) => {
            clearTimeout(timer);
            this.message = message;
            this.l_titleMessage = this.message.title;
            this.l_content = this.message.text;
            this.l_msgType = this.message.type;
            this.l_visible = true;

            timer = setTimeout(() => {
                console.log("remove toast");
                this.l_visible = false;
            }, 5000);
        });
    },
    methods : { 
        closeNotification() {
            this.l_visible = false;
        },
    }
    
}
</script>