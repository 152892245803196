<template>
  <div id="app" class="min-h-screen 
          bg-indigo-50 text-indigo-700 
          dark:bg-indigo-900 dark:text-indigo-50">
      <vue-progress-bar></vue-progress-bar>
      <toast-message></toast-message>
      <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  beforeMount() {
    this.$store.dispatch('initTheme', null, {root:true})
  },
}
</script>

<style>
@import url('https://rsms.me/inter/inter.css');
#app {
    font-family: 'Inter var', Helvetica, Arial, sans-serif;
}
</style>
